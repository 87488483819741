import Head from "next/head"

import { useGlueJar } from "../../lib/gluejar";
import QrcodeDecoder from "../../lib/qr";
import { useState } from "react";
import { QRCode } from "jsqr";

function QRImage({ image }: { image: string }) {
  const [data, setData] = useState<QRCode>();
  const [errored, setErrored] = useState(false);

  const qr = new QrcodeDecoder();
  qr.decodeFromImage(image).then(data => {
    if (data != null && typeof data === "object") {
      setData(data)
    } else {
      setErrored(true);
    }
  }).catch(err => {
    setData(undefined);
    setErrored(true);
  });

  return <>
    <article>
      <img src={image} alt={"Pasted image: " + image} />
      {errored
        ? <strong>There was an error decoding this QR code.</strong>
        : data === undefined
          ? <p>Parsing...</p>
          : <code>{data.data}</code>}
    </article>
  </>
}

export default function Home() {
	const { pasted, error } = useGlueJar();

  return (
    <>
      <div className="app">
        <Head>
          <title>QR Decoder</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <main>
          <header>
          <h1>QR Decoder</h1>
          <p>{"Paste an image :)"}</p>
          </header>

          {error !== null && <span>{error}</span>}
          <div>
            {pasted.length > 0 &&
              pasted.map((image) => (
                <QRImage image={image} key={image} />
              ))}
          </div>
        </main>

        <footer>
            A web tool by <a href="https://som.codes">Charlotte Som</a>.
        </footer>
      </div>
    </>
  )
}
